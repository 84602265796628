/* eslint-disable camelcase */
import React from 'react'
import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Billboard,
  SplitContent,
  VariableContent,
  PackageCard,
  LeshenPhoneCTA,
  Image,
  List,
  ListItem,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import { Stack, Columns, Column, Typography, Button } from '@leshen/ui'
import { Done } from 'emotion-icons/material'
import { graphql, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import siteTheme from '../../theme'
import SeoCtaBar from '../components/SeoCtaBar'

const CityTemplate = ({ data, pageContext }) => {
  const { Page_Path, State, City_Split_Copy, City } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const brandyPackageList = ['ctl-copper100', 'ctl-fiber-940c']

  const packageList = brandyPackageList
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: '',
        robots: 'noindex,nofollow',
        title: `CenturyLink Internet in ${City}`,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: null,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            mainContent={
              <>
                <Typography variant="h1">
                  CenturyLink Internet: Connecting {City} to the world
                </Typography>
                <Typography variant="h4">
                  With CenturyLink in {City}, you get:
                </Typography>
                <List style={{ marginBottom: '16px' }}>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Unlimited data</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">No annual contract</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">24/7 tech support</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <LeshenPhoneCTA variant="hero" color="primary">
                  Call Now
                </LeshenPhoneCTA>
              </>
            }
            image={
              pageContext.cityHeroImage && (
                <Image
                  data={data.cityHeroImage?.cloudinary[0]?.gatsbyImageData}
                  isImageCritical
                />
              )
            }
          />
          <SeoCtaBar />
          <VariableContent
            style={{ backgroundColor: siteTheme.colors.light }}
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink Internet in Salt Lake City in {City}
                </Typography>
                <Typography variant="h5">
                  Pick a CenturyLink Internet plan that fits your needs.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                    content={
                      <>
                        <LinkButton
                          to="/cart"
                          CustomLink={GatsbyLink}
                          color="primary"
                        >
                          Order Online
                        </LinkButton>
                        {/* Disabled lint line due to Gatsby api named
                        variable */}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
                      </>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  {`CenturyLink High Speed Internet: We've Got Options`}
                </Typography>
                <Typography>
                  {`Find the speeds you want with CenturyLink High-Speed Internet
                  or CenturyLink Fiber. Each connection comes with dependable
                  service and internet rates that won't empty your wallet.
                  There's fast and then there's CenturyLink fast.`}
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl" centeredContent>
              <Columns cardStyled>
                <Column>
                  <Image
                    data={data.speedCardOne.cloudinary[0].gatsbyImageData}
                    hasMarginBottom
                  />
                  <Typography variant="h4">
                    Simply Unlimited Internet
                  </Typography>
                  <Typography>
                    {`Simply Unlimited Internet is just what it sounds like. Get
                    unlimited data, so you can stream, surf, and download as
                    much as you want. With Simply Unlimited Internet you'll get:`}
                  </Typography>
                  <List>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          Virtually lag-free connection
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">In-home Wi-Fi</Typography>
                      </Typography>
                    </ListItem>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          24/7 technical support
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                </Column>
                <Column>
                  <Image
                    data={data.speedCardTwo.cloudinary[0].gatsbyImageData}
                    hasMarginBottom
                  />
                  <Typography variant="h4">Fiber Internet</Typography>
                  <Typography>
                    Keep everyone in your home connected with plenty of speed
                    and bandwidth thanks to CenturyLink Fiber Internet. This
                    lightning-fast fiber-optic connection offers your family:
                  </Typography>
                  <List>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          A fiber-fast connection
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">Unlimited data</Typography>
                      </Typography>
                    </ListItem>
                    <ListItem icon={<Done />}>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          24/7 techical support
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            style={{ backgroundColor: siteTheme.colors.light }}
            customLink={GatsbyLink}
            image={
              <Image
                data={data.girlChillingWithLaptop.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink in {State_Name}
                </Typography>
                <Typography>{City_Split_Copy}</Typography>
                <Button color="primary" to="/cart">
                  Order Online
                </Button>
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.laptop.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Speak with an agent to find the best deals in your area
                </Typography>
                <Typography>
                  Finding the perfect CenturyLink Internet plan is easy with the
                  help of our expert agents. Call to get answers to your
                  questions about speeds, availability, and bundling.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
        </>
      }
    />
  )
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($cityHeroImage: String!, $pagePath: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerHighspeedCenturylinkAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          City
          City_Split_Copy
        }
      }
    }
    cityHeroImage: contentfulMedia(identifier: { eq: $cityHeroImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    speedCardOne: contentfulMedia(
      contentful_id: { eq: "1oFYYNukcEJ9wsudrqsxwt" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    speedCardTwo: contentfulMedia(
      contentful_id: { eq: "3wYa4kJZPF9I9EwkfGvW4C" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    girlChillingWithLaptop: contentfulMedia(
      contentful_id: { eq: "124JSyh5tocrevzZNUIO5U" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    laptop: contentfulMedia(contentful_id: { eq: "3W5Rp5uBedbDIO546tfIll" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-authorized" }
    ) {
      symbol
      text
    }
    footerDisclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-allrightsreserved" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "a9940654-ae1f-5f31-9da4-4b870f4130f1" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "c37a6ce6-047c-53ee-99ee-569d1479e000" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
